button {
    height: 32px;
    min-width: 32px;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background: white;
    border: 1px solid transparent;
    border-bottom: 3px solid #a0a0a0;
    border-radius: 3px;
    padding: 8px 24px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: auto;
    height: 45px;
  }

  .fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s;
  }
  
  .stack {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #606060;
  }
  
  .bg {
    background: #25252515;
  }


.fullscreen.notready .continue-link {
    cursor: auto;
    color: #606060;
  }
  
  .fullscreen.ready .continue-link {
    cursor: pointer;
    color: white;
  }
  
  .fullscreen.clicked {
    pointer-events: none;
    opacity: 0;
  }
 
  .player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}