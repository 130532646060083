a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.fullW {
  top: 10px;
}

.normalW {
  top: 45px;
}

.wrapper {
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.wrapperLayer {
  position: fixed;
  top: 45px; /* 45px */
  z-index: 15;
  display: flex;
  justify-content: center;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.slideBox {
  position: fixed;
  /*top: 142px;  45px */
  /*left: 30px;*/
  padding: 0px;
  z-index: 15;
  overflow:hidden;
  display: flex;
  justify-content: center;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.deck {
  position: absolute;
  top: 45px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deckLayer {
  position: absolute;
  top: 0px;
  z-index: 15;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deck > div {
  touch-action: none;
  background-color: white;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.slideLayer {
  position: absolute;
  overflow: hidden;
  top: 0px;
  z-index: 15;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slideLayer > div {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.slideLayer img {
  -drag: none;
  width: '80%';
  overflow: cover;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
