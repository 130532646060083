@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");
/*
$body-font: "Poppins", "Helvetica", "sans-serif";
$headers-font: "Poppins", "Helvetica", "sans-serif";

// PADDING
$padding-width--heavy: 0px 70px;
$padding-width--mobile: 0px 16px;

// COLORS
$light-gray: #f4f4f4;
$primary-red: #ed1e28;
$primary-red--20: rgba(237, 30, 40, 0.2);
$secondary-red: #fe6d62;
$secondary-blue: #91b3fa;
$secondary-blue--40: rgba(145, 179, 250, 0.4);
$accent-gray: #eeeaeb;
$canvas: #ffffff;
*/

.progress-container {
  margin-top: 5rem;
  padding: 0px 70px;
}



.progress-bar {
  background-color: transparent;
  border: 3px #f4f4f4 solid;
  border-radius: 20px;
  top: 8px;  
  margin: 0px 0 0px 0;
  height: 30px;
}

.completed-bar {
    background: linear-gradient(to left, #061361, #9f9cc9);
    border-radius: 20px;
    color: z#f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: width 2s ease 0.4s;
    padding: 0 8px;
}
.completed-bar__dashed {
      width: 96%;
      border: 2px dotted #ffffff00;
    }

.completed-bar__truck {
      font-size: 1.5rem;
      margin-left: 4px;
    }



.progress-information {
  display: none;
  justify-content: space-between;
  padding: 0 4px;
}


p {
  font-family: "Poppins", "Helvetica", "sans-serif";
  letter-spacing: 1px;
  line-height: 1.5;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1rem;
  color: rgba(48, 48, 48, 0.6);
}


